import type { Environment } from './types';
import { sharedDevAuEnv, sharedDevEnv, sharedJuniperJpEnv } from './shared';

export const env: Environment = {
  ...sharedDevEnv,
  ...sharedJuniperJpEnv,
  ...sharedDevAuEnv,
  adminAppUrl: 'https://admin.dev.myjuniper.jp',
  logoUrl: 'https://assets.myjuniper.co.uk/juniper-logo.svg',
};
